import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import axiosInstance from '../../utils/axiosInstance'
import { toast } from 'react-toastify'
import { URL } from '../../constants/url'

export interface Cities {
  country: {
    iso: string
    name: string
  }
  id: number
  name: string
}

export interface InitState {
  isPending: boolean
  cities: Cities[] | null

  isPendingGetCities: boolean
  isPendingAddCities: boolean
  isPendingDeleteCities: boolean
}

const initialState: InitState = {
  cities: null,
  isPending: false,
  isPendingGetCities: false,
  isPendingAddCities: false,
  isPendingDeleteCities: false,
}

export const fetchCities = createAsyncThunk(
  'cities/fetchCities',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(URL.cities)
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

type fetchAddCitiesProps = {
  country: string
  name: string
  prevValue?: number
}

export const fetchAddCities = createAsyncThunk(
  'cities/fetchAddCities',
  async ({ country, name, prevValue }: fetchAddCitiesProps, thunkAPI) => {
    const formData = new FormData()
    formData.append('name', name)

    if (prevValue) {
      formData.set('_method', 'put')
    }

    try {
      const response: AxiosResponse = await axiosInstance.post(
        `${URL.countries}/${country}/cities${prevValue ? `/${prevValue}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return {
          ...response.data,
          id: prevValue ? prevValue : response.data.id,
        }
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const fetchDeleteCities = createAsyncThunk(
  'cities/fetchDeleteCities',
  async ({ country, id }: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.delete(
        `${URL.countries}/${country}/cities/${id}`
      )
      if (response.statusText === 'OK') {
        return { ...response.data, id }
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.pending, (state, action) => {
        return { ...state, isPendingGetCities: true }
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        return {
          ...state,
          cities: action.payload.data,
          isPendingGetCities: false,
        }
      })
      .addCase(fetchCities.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isPendingGetCities: false }
      })

    builder.addCase(fetchAddCities.pending, (state) => {
      return {
        ...state,
        isPendingAddCities: true,
      }
    })
    builder.addCase(fetchAddCities.fulfilled, (state, action) => {
      toast.success('Сохранено!')

      const isNoteExist =
        state.cities &&
        state.cities.find((cities) => cities.id === action.payload.id)

      if (isNoteExist) {
        return {
          ...state,
          isPendingAddCities: false,

          cities:
            state.cities &&
            state.cities.map((cities) =>
              cities.id === action.payload.id ? action.payload : cities
            ),
        }
      } else {
        return {
          ...state,
          isPendingAddCities: false,

          cities: [action.payload, ...current(state.cities as [])],
        }
      }
    })

    builder
      .addCase(fetchAddCities.rejected, (state, action) => {
        toast.error(action.payload as string)

        return {
          ...state,
          isPendingAddCities: false,
        }
      })

      .addCase(fetchDeleteCities.pending, (state) => {
        //   state.isPendingDeleteIncoming = true
        return { ...state, isPendingDeleteCities: true }
      })

      .addCase(
        fetchDeleteCities.fulfilled,
        (state, action: PayloadAction<{ id: number }>) => {
          toast.success('Удалено!')
          return {
            ...state,
            isPendingDeleteCities: false,
            cities:
              state?.cities &&
              state?.cities.filter((item) => item.id !== action.payload.id),
          }
        }
      )

      .addCase(fetchDeleteCities.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isPendingDeleteCities: false }
      })
  },
})

export default citiesSlice.reducer
