import { useEffect, useState } from 'react'
import styles from './ReportsCalendarCommon.module.css'
import { DateRange, DefinedRange, Range } from 'react-date-range'

import ru from 'date-fns/locale/ru'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css'
import classNames from 'classnames'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { DEFINEDS, staticRangesArray } from '../../../constants/date'
import { setCalendarRangeCommon } from '../../../redux/reducers/report-filter'
import { useDispatch, useSelector } from 'react-redux'
import EditButton from '../../../components/buttons/actions-button/EditButton'
import DeleteButton from '../../../components/buttons/actions-button/DeleteButton'
import {
  fetchReportsCommon,
  setCurrentReportCommon,
  setCurrentReportId,
  setIsVisibleDeleteModalReportCommon,
  setIsVisibleModalReportCommon,
} from '../../../redux/reducers/reports-common'
import { AppDispatch } from '../../../redux/store'
import { reportsCommonSelector } from '../../../redux/selectors/reports-common-selector'
import { getHost } from '../../../utils/getHost'
import { setIsVisibleModalReport } from '../../../redux/reducers/reports'
import { startOfDay, endOfDay } from 'date-fns'

const getSessionStorageData = (key: string) => {
  const data = sessionStorage.getItem('reportFilter')
  return data ? JSON.parse(data)[key] : null
}
const getStatus = (status: any) => {
  switch (status) {
    case 'draft':
      return 'Черновик'
    case 'sent':
      return 'Отправлен'
    case 'closed':
      return 'Закрыт'
    default:
      return ''
  }
}
const getDate = (isoDateString: string) => {
  if (!isoDateString) return 'В пути'
  const date = new Date(isoDateString)

  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()

  const formattedDate = `${day}.${month}.${year}`
  return formattedDate
}

function ReportsCalendarCommon({
  calendarRangeCommon,
  isVisibleForm,
  setIsVisibleForm,
}: any) {
  const { reportsCommon } = useSelector(reportsCommonSelector)
  const dispatch = useDispatch<AppDispatch>()
  const [isOpen, setIsOpen] = useState(false)
  const [clickCount, setClickCount] = useState(0)
  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false)

  const ref = useOutsideClick(() => {
    setIsOpen(false)
  })

  useEffect(() => {
    dispatch(
      fetchReportsCommon({
        dateFrom: startOfDay(calendarRangeCommon[0].startDate),
        dateTo: endOfDay(calendarRangeCommon[0].endDate),
      })
    )
  }, [])

  useEffect(() => {
    if (clickCount >= 2) {
      setIsOpen(false)
      setClickCount(0)
    }
  }, [clickCount])

  useEffect(() => {
    const storedSelectedTrucks = getSessionStorageData('selectedTrucks')
    const storedSelectedEmployees = getSessionStorageData('selectedDrivers')
    if (storedSelectedTrucks?.length || storedSelectedEmployees?.length) {
      setIsVisibleForm(!isVisibleForm)
    }
  }, [])

  const handleEditClick = (item: any) => {
    dispatch(setCurrentReportCommon(item))
    dispatch(setIsVisibleModalReportCommon(true))
  }

  const handleDeleteReport = (item: any) => {
    dispatch(setCurrentReportCommon(item))
    dispatch(setIsVisibleDeleteModalReportCommon(true))
  }

  const formatExpenses = (expenses: any) => {
    const number = Number(expenses)
    if (number % 1 === 0) {
      return number
    } else {
      const formatted = number.toFixed(2)
      return formatted.endsWith('0') ? formatted.slice(0, -1) : formatted
    }
  }

  return (
    <div className={classNames('row', styles.rowJusify)}>
      <div
        className={classNames(
          `col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 account-left-col , ${styles.datePicker}`
        )}
      >
        <div className="row">
          <div
            className="col-12 col-sm-12 col-md-12"
            style={{ textAlign: 'left' }}
          >
            <div className={styles.calendar}>
              <DefinedRange
                className={styles.range}
                onChange={(item) => {
                  dispatch(setCalendarRangeCommon([item.selection]))
                }}
                rangeColors={['#11e651']}
                ranges={calendarRangeCommon}
                staticRanges={staticRangesArray}
              />
              <DateRange
                className={styles.dateRange}
                rangeColors={['#0f6dba']}
                locale={ru}
                dateDisplayFormat="dd.MM.yyyy"
                editableDateInputs={true}
                onChange={(item) => {
                  dispatch(setCalendarRangeCommon([item.selection]))
                }}
                moveRangeOnFirstSelection={false}
                ranges={calendarRangeCommon}
                months={2}
              />
            </div>
          </div>
        </div>
      </div>
      {<div className={styles.isMobileMargin} />}
      <div className={styles.dateRangeMobile} ref={ref}>
        <div className={styles.flexInput}>
          <div
            className={styles.inputCalendar}
            onClick={() => {
              setIsVisibleCalendar((prev) => !prev)
              setIsOpen(!isOpen)
            }}
          >
            <input
              type="text"
              readOnly
              //@ts-ignore
              value={`${calendarRangeCommon[0].startDate.toLocaleDateString()} - ${calendarRangeCommon[0].endDate.toLocaleDateString()}`}
              className={classNames(styles.dateInput)}
            />
            <i
              className={`${styles.iconArrowBlue}  ${styles.iconArrowBlueInput}  ${isVisibleCalendar ? styles.iconRotateInput : ''}`}
            />
            <i className={`${styles.calendarInput}`} />
          </div>
        </div>

        {isOpen && (
          <>
            <div className={styles.calendar}>
              <DefinedRange
                className={styles.range}
                onChange={(item) => {
                  setClickCount(2)
                  dispatch(setCalendarRangeCommon([item.selection]))
                }}
                inputRanges={[]}
                rangeColors={['#11e651']}
                ranges={calendarRangeCommon}
                staticRanges={staticRangesArray}
              />
              <DateRange
                className={styles.dateRange}
                rangeColors={['#0f6dba']}
                locale={ru}
                dateDisplayFormat="dd.MM.yyyy"
                editableDateInputs={true}
                onChange={(item) => {
                  setClickCount((prev) => (prev += 1))
                  dispatch(setCalendarRangeCommon([item.selection]))
                }}
                moveRangeOnFirstSelection={false}
                ranges={calendarRangeCommon}
                months={2}
              />
            </div>
          </>
        )}
      </div>

      {/* <!-- RIGHT --> */}
      <div
        className={classNames(
          'col-sm-12 col-md-7 col-lg-8 col-xl-9 account-right-col',
          styles.accountRightCol
        )}
      >
        {reportsCommon.map((item) => (
          <div
            className={classNames(
              styles.bgWhite,
              styles.border10,
              styles.bxShadow,
              styles.small2,
              styles.docItem,
              'pl-2',
              'pr-2',
              'mb-3'
            )}
          >
            <div className={classNames(styles.row, 'row')}>
              <div
                className={classNames(
                  styles.small3,
                  'col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2 pr-2 pt-1 pb-1'
                )}
              >
                <div>
                  <span
                    className={classNames(
                      item.status === 'draft' ? styles.bgRed : '',
                      item.status === 'sent' ? styles.bgTurquoise : '',
                      item.status === 'closed' ? styles.bgDarkBlue : '',
                      styles.borderRadius5,
                      'd-inline-block pt-0 pb-0 pl-2 pr-2 mb-1'
                    )}
                  >
                    <b>{item.number}</b>
                  </span>
                  <br />
                  <span
                    className={classNames(
                      'd-inline-block mb-1',
                      item.status === 'draft' ? styles.colorRed : '',
                      item.status === 'sent' ? styles.colorTurquoise : ''
                    )}
                  >
                    {getStatus(item.status)}
                  </span>
                  <br />
                </div>
              </div>
              <div
                className={classNames(
                  styles.small3,
                  'col-4 col-sm-3 col-md-3 col-lg-4 col-xl-3 pl-2 pr-2 pt-1 pb-1'
                )}
              >
                {getDate(item.departure)}–&nbsp;{getDate(item.arrival)}
              </div>
              <div className="doc-car col-4 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-2 pt-1 pb-1">
                <div
                  className={classNames(
                    styles.colorLightGray,
                    styles.small3,
                    'd-block d-xl-none'
                  )}
                >
                  Расходы
                </div>
                <b className={classNames(styles.colorRed, styles.nowrap)}>
                  {formatExpenses(item?.all_expenses) || ''}
                </b>
              </div>
              <div
                className={classNames(
                  styles.docButtons,
                  'col-12 col-sm-3 col-md-4 col-lg-3 col-xl-4 pt-1 pb-1'
                )}
                style={{ textAlign: 'right' }}
              >
                {item?.report_pdf_url ? (
                  <a
                    href={`${getHost()}${item?.report_pdf_url}`}
                    target="_blank"
                    data-tooltip="Скачать отчёт"
                    className={classNames(
                      styles.btn,
                      styles.btnBlueOutline,
                      styles.btnSmall2,
                      styles.btnIcon,
                      styles.tooltip,
                      'tooltip-left-center'
                    )}
                    rel="noreferrer"
                  >
                    <div className={classNames(styles.tooltipText)}>
                      Скачать отчёт
                    </div>
                    <i
                      className={classNames(styles.icon, styles.iconDownload)}
                    ></i>
                  </a>
                ) : null}
                <EditButton
                  onClick={(e) => {
                    e.preventDefault()

                    handleEditClick(item)
                  }}
                />

                <DeleteButton onClick={() => handleDeleteReport(item)} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ReportsCalendarCommon
