import { useState } from 'react'
import styles from './Settings.module.css'
import { AppDispatch } from '../../redux/store'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import HeaderMain from '../../components/header-main/HeaderMain'
import CommonSettings from './common-settings/CommonSettings'

const MobileSettings = ({ currentTab, setCurrentTab }: any) => {
  const title = [
    { key: 1, value: 'Шаблоны документов' },
    { key: 2, value: 'Шаблоны писем' },
    { key: 3, value: 'Общие настройки' },
  ]
  return (
    <>
      <div className="dropdown d-block d-sm-none mb-3">
        <select
          value={currentTab}
          onChange={(e) => {
            setCurrentTab(Number(e.target.value))
          }}
        >
          <option value="" key={undefined}>
            — Выберите —
          </option>
          {title?.map((item) => (
            <option key={item.key} value={item.key}>
              {item.value}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

function Settings() {
  const dispatch = useDispatch<AppDispatch>()
  const [currentTab, setCurrentTab] = useState(3)

  interface VisibleDropdowns {
    [key: number]: boolean
  }

  const [visibleDropdowns, setVisibleDropdowns] = useState<VisibleDropdowns>({})

  const toggleDropdown = (clientId: number) => {
    setVisibleDropdowns((prevVisibleDropdowns) => ({
      ...prevVisibleDropdowns,
      [clientId]: !prevVisibleDropdowns[clientId],
    }))
  }

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />

          {/* <div
            className={classNames(
              'd-none d-sm-flex flex-wrap align-items-end tabs radio-tabs mb-5',
            )}
            style={{ textAlign: 'center' }}
          >
            <div className="mt-1 mb-1">
              <input
                name="setts_radio"
                type="radio"
                value={1}
                checked={currentTab === 1}
              />
              <label onClick={() => setCurrentTab(1)}>Шаблоны документов</label>
            </div>
            <div className="mt-1 mb-1">
              <input
                name="setts_radio"
                id="setts_radio3"
                type="radio"
                value={2}
                checked={currentTab === 2}
              />
              <label onClick={() => setCurrentTab(2)}>Шаблоны писем</label>
            </div>
            <div className="mt-1 mb-1">
              <input
                name="setts_radio"
                id="setts_radio1"
                type="radio"
                value={3}
                checked={currentTab === 3}
              />
              <label onClick={() => setCurrentTab(3)}>Общие настройки</label>
            </div>
          </div> */}
          {/* <div>
            <MobileSettings
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </div> */}
          {currentTab === 3 && <CommonSettings />}
        </div>
      </div>
    </div>
  )
}

export default Settings
