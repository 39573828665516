import authReducer from './reducers/auth'
import initReducer from './reducers/init'
import carsReducer from './reducers/cars'
import serviceReducer from './reducers/services'
import reportsReducer from './reducers/reports'
import usersReducer from './reducers/users'
import companyReducer from './reducers/company'
import reportFilter from './reducers/report-filter'
import clientsReducer from './reducers/clients'
import notesReducer from './reducers/notes'
import phonesReducer from './reducers/phones'
import contractsReducer from './reducers/documents/contracts'
import partsReducer from './reducers/parts'
import notificationsReducer from './reducers/notifications'
import reportsCommonReducer from './reducers/reports-common'
import countriesReducer from './reducers/countries'
import dictionaryReducer from './reducers/dictionary'
import settingsReducer from './reducers/settings'
import citiesReducer from './reducers/cities'

import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    init: initReducer,
    cars: carsReducer,
    reports: reportsReducer,
    users: usersReducer,
    company: companyReducer,
    reportFilter: reportFilter,
    service: serviceReducer,
    clients: clientsReducer,
    notes: notesReducer,
    phones: phonesReducer,
    contracts: contractsReducer,
    parts: partsReducer,
    notifications: notificationsReducer,
    reportsCommon: reportsCommonReducer,
    countries: countriesReducer,
    dictionary: dictionaryReducer,
    settings: settingsReducer,
    cities: citiesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
