import styles from './Documents.module.css'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import HeaderMain from '../../components/header-main/HeaderMain'

import 'react-datepicker/dist/react-datepicker.css'
import DocumentsContracts from './contracts/DocumentsContracts'
import { useState } from 'react'
import DocumentsTravelAllowances from './travel-allowances/TravelAllowances'

const MobileSettings = ({ currentTab, setCurrentTab }: any) => {
  const title = [
    { key: 1, value: 'Договора' },
    { key: 2, value: 'Счета' },
    { key: 3, value: 'Командировочные' },
    { key: 4, value: 'Приказы' },
  ]
  return (
    <>
      <div className="dropdown d-block d-sm-none mb-3">
        <select
          value={currentTab}
          onChange={(e) => {
            setCurrentTab(Number(e.target.value))
          }}
        >
          {title?.map((item) => (
            <option key={item.key} value={item.key}>
              {item.value}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

function Documents() {
  const dispatch = useDispatch<AppDispatch>()
  const [currentTab, setCurrentTab] = useState(1)

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />
          <div
            className={classNames(
              'd-none d-sm-flex flex-wrap align-items-end tabs radio-tabs mb-5',
              styles.radioTabs
            )}
            style={{ textAlign: 'center' }}
          >
            <div className="mt-1 mb-1">
              <input
                name="setts_radio"
                type="radio"
                value={1}
                checked={currentTab === 1}
              />
              <label onClick={() => setCurrentTab(1)}>Договора</label>
            </div>
            <div className="mt-1 mb-1">
              <input
                name="setts_radio"
                id="setts_radio3"
                type="radio"
                value={2}
                checked={currentTab === 2}
              />
              <label onClick={() => setCurrentTab(2)}>Счета</label>
            </div>
            <div className="mt-1 mb-1">
              <input
                name="setts_radio"
                id="setts_radio1"
                type="radio"
                value={3}
                checked={currentTab === 3}
              />
              <label onClick={() => setCurrentTab(3)}>Командировочные</label>
            </div>
            <div className="mt-1 mb-1">
              <input
                name="setts_radio"
                id="setts_radio1"
                type="radio"
                value={3}
                checked={currentTab === 4}
              />
              <label onClick={() => setCurrentTab(4)}>Приказы</label>
            </div>
          </div>
          <div>
            <MobileSettings
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </div>
          {currentTab === 1 && <DocumentsContracts />}
          {currentTab === 3 && <DocumentsTravelAllowances links="3" />}
          {currentTab === 4 && <DocumentsTravelAllowances links="4" />}
        </div>
      </div>
    </div>
  )
}

export default Documents
