import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import axiosInstance from '../../utils/axiosInstance'
import { convertKeysToCamelCase } from '../../utils/objKeysToCamelCase'
import { toast } from 'react-toastify'
import { URL } from '../../constants/url'

export interface Dictionary {
  slug: string
  name: string
  value: number
}

export interface InitState {
  isPending: boolean
  dictionary: Dictionary[] | null
  isPendingGetDictionary: boolean
  isPendingAddDictionary: boolean
  isPendingDeleteDictionary: boolean
}

const initialState: InitState = {
  dictionary: null,
  isPending: false,
  isPendingGetDictionary: false,
  isPendingAddDictionary: false,
  isPendingDeleteDictionary: false,
}

// https://dev.alex-logistic.com/api/dictionary/expenses

export const fetchDictionaryExpenses = createAsyncThunk(
  'dictionary/fetchDictionaryExpenses',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        URL.dictionary + '/expenses'
      )
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const fetchDictionaryDeleteExpenses = createAsyncThunk(
  'dictionary/fetchDictionaryDeleteExpenses',
  async (id: string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.delete(
        `${URL.dictionary}/expenses/${id}`
      )
      if (response.statusText === 'OK') {
        return { ...response.data, id }
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

type fetchAddDictionaryExpensesProps = {
  slug: string
  name: string
  prevValue?: string
}

export const fetchAddDictionaryExpenses = createAsyncThunk(
  'dictionary/fetchAddDictionaryExpenses',
  async (
    { slug, name, prevValue }: fetchAddDictionaryExpensesProps,
    thunkAPI
  ) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('slug', slug)

    if (prevValue) {
      formData.set('_method', 'put')
    }
    try {
      const response: AxiosResponse = await axiosInstance.post(
        `${URL.dictionary}/expenses${prevValue ? `/${prevValue}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return { ...response.data, slug: prevValue ? prevValue : slug }
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDictionaryExpenses.pending, (state, action) => {
        return { ...state, isPendingGetDictionary: true }
      })
      .addCase(fetchDictionaryExpenses.fulfilled, (state, action) => {
        return {
          ...state,
          dictionary: action.payload.data,
          isPendingGetDictionary: false,
        }
      })
      .addCase(fetchDictionaryExpenses.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isPendingGetDictionary: false }
      })

      .addCase(fetchDictionaryDeleteExpenses.pending, (state) => {
        //   state.isPendingDeleteIncoming = true
        return { ...state, isPendingDeleteDictionary: true }
      })

      .addCase(
        fetchDictionaryDeleteExpenses.fulfilled,
        (state, action: PayloadAction<{ id: number }>) => {
          toast.success('Удалено!')

          return {
            ...state,
            isPendingDeleteDictionary: false,
            dictionary:
              state?.dictionary &&
              state?.dictionary.filter(
                (item) => item.slug !== String(action.payload.id)
              ),
          }
        }
      )

      .addCase(fetchDictionaryDeleteExpenses.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isPendingDeleteDictionary: false }
        //   state.isVisibleDeleteModalIncoming = false
        //   state.isPendingDeleteIncoming = false
        //   state.isRejectDeleteIncoming = true
        //   state.error = action.payload as string
      })

    builder.addCase(fetchAddDictionaryExpenses.pending, (state) => {
      return {
        ...state,
        isPendingAddDictionary: true,
      }
    })
    builder.addCase(fetchAddDictionaryExpenses.fulfilled, (state, action) => {
      toast.success('Сохранено!')

      const isNoteExist =
        state.dictionary &&
        state.dictionary.find(
          (dictionary) => dictionary.slug === action.payload.slug
        )

      if (isNoteExist) {
        return {
          ...state,
          isPendingAddDictionary: false,
          dictionary:
            state.dictionary &&
            state.dictionary.map((dictionary) =>
              dictionary.slug === action.payload.slug
                ? action.payload
                : dictionary
            ),
        }
      } else {
        return {
          ...state,
          isPendingAddDictionary: false,
          dictionary: [action.payload, ...current(state.dictionary as [])],
        }
      }
    })

    builder.addCase(fetchAddDictionaryExpenses.rejected, (state, action) => {
      toast.error(action.payload as string)
      return {
        ...state,
        isPendingAddDictionary: false,
      }
    })
  },
})

export default dictionarySlice.reducer
