import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import axiosInstance from '../../utils/axiosInstance'
import { convertKeysToCamelCase } from '../../utils/objKeysToCamelCase'
import { toast } from 'react-toastify'
import { URL } from '../../constants/url'

export interface Country {
  iso: string
  name: string
}

export interface InitState {
  isPending: boolean
  countries: Country[] | null
  isPendingGetCountry: boolean
  isPendingAddCountry: boolean
  isPendingDeleteCountry: boolean
}

const initialState: InitState = {
  countries: null,
  isPending: false,
  isPendingGetCountry: false,
  isPendingAddCountry: false,
  isPendingDeleteCountry: false,
}

export const fetchCountries = createAsyncThunk(
  'countries/fetchCountries',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(URL.countries)
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const fetchDeleteCountries = createAsyncThunk(
  'countries/fetchDeleteCountries',
  async (id: string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.delete(
        `${URL.countries}/${id}`
      )
      if (response.statusText === 'OK') {
        return { ...response.data, id }
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

type fetchAddCountriesProps = {
  iso: string
  name: string
  prevValue?: string
}
export const fetchAddCountries = createAsyncThunk(
  'countries/fetchAddCountries',
  async ({ iso, name, prevValue }: fetchAddCountriesProps, thunkAPI) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('iso', iso)

    if (prevValue) {
      formData.set('_method', 'put')
    }
    try {
      const response: AxiosResponse = await axiosInstance.post(
        `${URL.countries}${prevValue ? `/${prevValue}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return { ...response.data, iso: prevValue ? prevValue : iso }
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state, action) => {
        return { ...state, isPendingGetCountry: true }
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        return {
          ...state,
          countries: action.payload.data,
          isPendingGetCountry: false,
        }
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isPendingGetCountry: false }
      })

      .addCase(fetchDeleteCountries.pending, (state) => {
        //   state.isPendingDeleteIncoming = true
        return { ...state, isPendingDeleteCountry: true }
      })

      .addCase(
        fetchDeleteCountries.fulfilled,
        (state, action: PayloadAction<{ id: number }>) => {
          toast.success('Удалено!')

          return {
            ...state,
            isPendingDeleteCountry: false,
            // isVisibleDeleteService: false,
            countries:
              state?.countries &&
              state?.countries.filter(
                (item) => item.iso !== String(action.payload.id)
              ),
          }
        }
      )

      .addCase(fetchDeleteCountries.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isPendingDeleteCountry: false }
      })

    builder.addCase(fetchAddCountries.pending, (state) => {
      return {
        ...state,
        isPendingAddCountry: true,
      }
    })
    builder.addCase(fetchAddCountries.fulfilled, (state, action) => {
      toast.success('Сохранено!')

      const isNoteExist =
        state.countries &&
        state.countries.find(
          (countries) => countries.iso === action.payload.iso
        )

      if (isNoteExist) {
        return {
          ...state,
          isPendingAddCountry: false,
          countries:
            state.countries &&
            state.countries.map((countries) =>
              countries.iso === action.payload.iso ? action.payload : countries
            ),
        }
      } else {
        return {
          ...state,
          isPendingAddCountry: false,

          countries: [action.payload, ...current(state.countries as [])],
        }
      }
    })

    builder.addCase(fetchAddCountries.rejected, (state, action) => {
      toast.error(action.payload as string)

      return {
        ...state,
        isPendingAddCountry: false,
      }
    })
  },
})

export default countriesSlice.reducer
