export const URL = {
  auth: 'api/auth',
  init: 'api/init',
  cars: 'api/cars',
  report: 'api/reports',
  reportCommons: 'api/reportCommons',
  user: 'api/users',
  company: 'api/company',
  clients: 'api/clients',
  document: 'api/documents',
  parts: 'api/parts',
  notifications: 'api/notifications',
  countries: 'api/countries',
  settings: 'api/settings',
  cities: 'api/cities',
  dictionary: 'api/dictionary',
} as const
