import classNames from 'classnames'
import HeaderMain from '../../components/header-main/HeaderMain'
import ReportsCalendar from './reports-calendar/ReportsCalendar'
import ReportsFilter from './reports-filter/ReportsFilter'
import ReportsResult from './reports-result/ReportsResult'
import styles from './Reports.module.css'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchReports } from '../../redux/reducers/reports'
import { AppDispatch } from '../../redux/store'
import {
  fetchCars,
  // fetchCarsForAdmins
} from '../../redux/reducers/cars'
import { fetchUsers } from '../../redux/reducers/users'
import { reportFilterSelector } from '../../redux/selectors/report-filter-selector'
import ReportsCalendarCommon from './reports-calendar-common/ReportsCalendarCommon'
import { reportsCommonSelector } from '../../redux/selectors/reports-common-selector'
import { fetchReportsCommon } from '../../redux/reducers/reports-common'

function Reports() {
  const dispatch = useDispatch<AppDispatch>()
  const { calendarRange, calendarRangeCommon } =
    useSelector(reportFilterSelector)
  const { selectedTrucks, selectedDrivers, selectedStatus } =
    useSelector(reportFilterSelector)

  // this filter need for sort reports (now don't working)
  const [orderBy, setOrderBy] = useState('id')
  const [orderDirection, setOrderDirection] = useState('desc')

  const getCars = selectedTrucks?.map((item) => item.id)

  const getEmployees = selectedDrivers?.map((item) => item.id)

  const [isVisibleForm, setIsVisibleForm] = useState(true)

  useEffect(() => {
    dispatch(fetchCars())
    dispatch(fetchUsers())
  }, [])

  const getReports = () => {
    dispatch(
      fetchReports({
        cars: getCars,
        employees: getEmployees,
        dateFrom: calendarRange[0].startDate,
        dateTo: calendarRange[0].endDate,
        status: selectedStatus,
        orderBy,
        orderDirection,
      })
    )
  }

  useEffect(() => {
    dispatch(
      fetchReports({
        dateFrom: calendarRange[0].startDate,
        dateTo: calendarRange[0].endDate,
        cars: getCars,
        employees: getEmployees,
        status: selectedStatus,
      })
    )
  }, [calendarRange])

  useEffect(() => {
    dispatch(
      fetchReportsCommon({
        dateFrom: calendarRangeCommon[0].startDate,
        dateTo: calendarRangeCommon[0].endDate,
      })
    )
  }, [calendarRangeCommon])

  const { currentReportTab } = useSelector(reportsCommonSelector)

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />

          {currentReportTab === '1' ? (
            <div className="row" style={{ justifyContent: 'space-evenly' }}>
              <ReportsCalendar
                calendarRange={calendarRange}
                isVisibleForm={isVisibleForm}
                setIsVisibleForm={setIsVisibleForm}
              />
              <div
                className={classNames(
                  'col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 account-right-col',
                  styles.accountRightCol
                )}
              >
                <ReportsFilter
                  selectedTrucks={selectedTrucks}
                  selectedDrivers={selectedDrivers}
                  selectedStatus={selectedStatus}
                  getReports={getReports}
                  isVisibleForm={isVisibleForm}
                />
                <ReportsResult
                  dateFrom={calendarRange[0].startDate}
                  dateTo={calendarRange[0].endDate}
                  getCars={getCars}
                  getEmployees={getEmployees}
                />
              </div>
            </div>
          ) : (
            <ReportsCalendarCommon
              calendarRangeCommon={calendarRangeCommon}
              isVisibleForm={isVisibleForm}
              setIsVisibleForm={setIsVisibleForm}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Reports
