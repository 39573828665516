import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import axiosInstance from '../../utils/axiosInstance'
import { convertKeysToCamelCase } from '../../utils/objKeysToCamelCase'
import { toast } from 'react-toastify'
import { URL } from '../../constants/url'

export interface Settings {
  slug: string
  name: string
  value: number
}

export interface InitState {
  isPending: boolean
  settings: Settings[] | null
}

const initialState: InitState = {
  settings: null,
  isPending: false,
}

export const fetchSettings = createAsyncThunk(
  'settings/fetchSettings',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(URL.settings)
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const fetchAddSettings = createAsyncThunk(
  'settings/fetchAddSettings',
  async ({ value }: any, thunkAPI) => {
    const formData = new FormData()

    formData.append('value', value)

    formData.set('_method', 'put')

    try {
      const response: AxiosResponse = await axiosInstance.post(
        `${URL.settings}/travelAllowances`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state, action) => {
        return { ...state, isPending: true }
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        const settings = convertKeysToCamelCase<Settings[]>(action.payload.data)
        return { ...state, settings: settings, isPending: false }
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isPending: false }
      })
  },
})

export default settingsSlice.reducer
