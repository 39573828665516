import React, { RefObject, useEffect, useState } from 'react'
import { Range } from 'react-date-range'
import { startOfDay, endOfDay } from 'date-fns'

import styles from '../Documents.module.css'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'

import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { formatDate } from '../../../utils/date'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import { fetchReports, Report } from '../../../redux/reducers/reports'
import { reportFilterSelector } from '../../../redux/selectors/report-filter-selector'
import { DEFINEDS } from '../../../constants/date'
import { reportsSelector } from '../../../redux/selectors/reports-selector'
import { driversAndAssistantsSelector } from '../../../redux/selectors/users-selector'
import { fetchUsers } from '../../../redux/reducers/users'
import { getHost } from '../../../utils/getHost'
//@ts-ignore
const calculateDays = (departure, arrival) => {
  const oneDay = 24 * 60 * 60 * 1000 // Количество миллисекунд в одном дне
  const departureDate = new Date(departure)
  const arrivalDate = new Date(arrival)
  const daysDifference = Math.ceil(
    //@ts-ignore
    Math.abs((departureDate - arrivalDate) / oneDay)
  )
  return `${daysDifference} дн.`
}
registerLocale('ru', ru)
interface DropDownProps {
  value: { id: string | number; name: string }
  items: Array<{ id: string | number; name: string }>
  refDropdown: RefObject<HTMLDivElement>
  handleButtonClick: () => void
  isSelectMenu: boolean
  handleChangeItem: (item: { id: string | number; name: string }) => void
  isVisibleAddBlock?: boolean
}

const COUNTRIES = [
  { id: '', name: 'Все страны' },
  { id: 'BY', name: 'Беларусь' },
  { id: 'RU', name: 'Россия' },
]

const STATUSES = [
  { id: '', name: 'Все статусы' },
  { id: 1, name: 'Оплачены' },
  { id: 0, name: 'Не оплачены' },
]

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    value,
    items,
    refDropdown,
    handleButtonClick,
    isSelectMenu,
    handleChangeItem,
    isVisibleAddBlock,
  } = props
  return (
    <div
      className={
        isVisibleAddBlock
          ? 'd-none d-sm-block col-sm-6 col-md-6 col-lg-3 col-xl-3'
          : 'col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3'
      }
    >
      <div
        ref={refDropdown}
        className={`${styles.dropdown} d-block`}
        style={{ textAlign: 'left' }}
      >
        <div
          onClick={handleButtonClick}
          className={classNames(
            styles.btn,
            styles.btnSmall,
            styles.btnInput,
            styles.btnDropdown,
            isSelectMenu && styles.btnDropdownActive
          )}
        >
          {value.name}
        </div>
        <div
          className={classNames(
            styles.dropdownContent,
            styles.dropdownSelect,
            isSelectMenu && styles.dropdownContentActive
          )}
        >
          {items.map((item) => (
            <div onClick={() => handleChangeItem(item)} key={item.id}>
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const FilterTravel = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [startDate, setStartDate] = useState<Date | undefined>(
    DEFINEDS.startOfMonth
  )
  const [endDate, setEndDate] = useState<Date | undefined>(DEFINEDS.endOfMonth)
  const { selectedDrivers } = useSelector(reportFilterSelector)
  const getEmployees = selectedDrivers?.map((item) => item.id)

  const currentYear = new Date().getFullYear()

  useEffect(() => {
    dispatch(
      fetchReports({
        dateFrom: startOfDay(DEFINEDS.startOfYear(currentYear)),
        dateTo: endOfDay(DEFINEDS.endOfYear(currentYear)),
        employees: getEmployees,
      })
    )
  }, [])

  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setStartDate(start || undefined)
    setEndDate(end || undefined)
  }

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  //Drivers
  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  const driversUser = useSelector(driversAndAssistantsSelector)

  const getDrivers = driversUser.map((item) => ({
    id: item.id,
    name: item.name,
  }))
  const [drivers, setDrivers] = useState<{ id: string | number; name: string }>(
    { id: '', name: 'Все водители' }
  )
  const [isSelectMenuDrivers, setIsSelectMenuDrivers] = useState(false)

  const refDrivers = useOutsideClick(() => {
    setIsSelectMenuDrivers(false)
  })

  const handleSelectChangeDrivers = (drivers: {
    id: string | number
    name: string
  }) => {
    setDrivers(drivers)

    setIsSelectMenuDrivers(false)
  }
  const handleButtonClickDrivers = () => {
    setIsSelectMenuDrivers((prev) => !prev)
  }

  useEffect(() => {
    // DATE
    if (startDate && endDate) {
      const start = new Date(startDate)
      start.setHours(0, 0, 0, 0)

      const end = new Date(endDate)
      end.setHours(23, 59, 59, 999)

      dispatch(
        fetchReports({
          //@ts-ignore
          dateFrom: start,
          //@ts-ignore
          dateTo: end,
          employees: [String(drivers.id)],
        })
      )
      setIsOpen(false)
    }
  }, [
    drivers.id,
    endDate,
    startDate,
    drivers,
    // reports
  ])
  const redData = useOutsideClick(() => {
    setIsOpen(false)
  })
  return (
    <div className={classNames(styles.filterForm, styles.filterDocs, 'mb-3')}>
      <div
        className={classNames(
          styles.tableFilterPanel,
          styles.small,
          'row',
          'align-items-end'
        )}
      >
        <div
          className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          // ref={redData}
        >
          <div
            ref={redData}
            id="docsfilter_date_input_wrapper"
            className="datePickerInput"
          >
            <input
              className={classNames(styles.calendarInput, styles.date)}
              type="text"
              onClick={handleClick}
              value={
                startDate && endDate
                  ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                  : 'Выберите диапазон дат'
              }
              readOnly
            />
            {isOpen && (
              <div className={styles.datePickerWrapper}>
                <DatePicker
                  popperClassName={styles.datePickerWrapper1}
                  calendarClassName={styles.datePickerWrapper1}
                  wrapperClassName={styles.datePickerWrapper1}
                  className={styles.datePickerWrapper1}
                  selected={startDate}
                  onChange={handleChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={2}
                  showPopperArrow={false}
                  locale="ru"
                  inline
                />
              </div>
            )}
          </div>
        </div>
        <DropDown
          value={drivers}
          items={[{ id: '', name: 'Все водители' }, ...getDrivers]}
          refDropdown={refDrivers}
          handleButtonClick={handleButtonClickDrivers}
          isSelectMenu={isSelectMenuDrivers}
          handleChangeItem={handleSelectChangeDrivers}
        />
      </div>
    </div>
  )
}

//@ts-ignore
function TravelAllowances({ reports, links }: { reports: Report[] }) {
  const getValue3 = (item: string) => links === '3' && !!item
  const getValue4 = (item: string) => links === '4' && !!item

  const isRender = reports.some(
    (item) =>
      (links === '3' && !!item.report_pdf_business_trip_url) ||
      (links === '4' && !!item.report_pdf_order_url)
  )
  if (!isRender) {
    return (
      <div
        style={{ textAlign: 'center' }}
        className="pt-5 pb-5 pl-5 pr-5 bg-white border-10"
      >
        <p>
          <i className="icon icon-70 opacity-03 icon-reports"></i>
        </p>
        <div className="font-big color-gray">
          Таких документов за выбранный период пока нет
        </div>
      </div>
    )
  }
  return (
    <>
      {
        <div
          className={classNames(
            styles.docItem,
            styles.small2,
            'd-none d-lg-block pl-2 pr-2',
            'mt-4 mb-2',
            'mb-3'
          )}
        >
          <div className="row" style={{ alignItems: 'center' }}>
            <div className="col-4 col-sm-3 col-md-4 col-lg-2">
              <span className={classNames(styles.colorGray)}>Номер</span>
            </div>
            <div className="col-8 col-sm-4 col-md-8 col-lg-2">
              <span className={classNames(styles.colorGray)}>Водитель</span>
            </div>
            <div className="col-4 col-sm-5 col-md-4 col-lg-3">
              <span className={classNames(styles.colorGray)}>Назначение</span>
            </div>
            <div className="col-5 col-sm-6 col-md-5 col-lg-3">
              <span className={classNames(styles.colorGray)}>Период</span>
            </div>
            <div className="col-3 col-sm-6 col-md-3 col-lg-2"></div>
          </div>
        </div>
      }

      {reports.map((item: Report) => {
        return (
          (getValue3(item?.report_pdf_business_trip_url) ||
            getValue4(item?.report_pdf_order_url)) && (
            <div
              className={classNames(
                styles.bgWhite,
                styles.border10,
                styles.bxShadow,
                styles.small2,
                styles.docItem,
                'pl-2',
                'pr-2',
                'mb-3'
              )}
            >
              <div
                className="row"
                style={{ alignItems: 'center', minHeight: '46px' }}
              >
                <div
                  className={classNames(
                    styles.colorGray,
                    '"doc-number col-4 col-sm-3 col-md-4 col-lg-2 pt-1 pb-1'
                  )}
                >
                  <b>{item.number}</b>
                </div>
                <div 
                className="doc-driver col-8 col-sm-4 col-md-8 col-lg-2 pt-1 pb-1">
                  <a>
                    <b>{item.user.name}</b>
                  </a>
                </div>
                <div className="col-4 col-sm-5 col-md-4 col-lg-3 pt-1 pb-1">
                  {
                    //@ts-ignore
                    item.from?.name
                  }
                  {', '}
                  {
                    //@ts-ignore
                    item.from?.country?.iso
                  }
                  {' - '}
                  {
                    //@ts-ignore
                    item.to?.name
                  }
                  {', '}
                  {
                    //@ts-ignore
                    item.to?.country?.iso
                  }
                </div>
                <div className="col-5 col-sm-6 col-md-5 col-lg-3 pt-1 pb-1">
                  {formatDate(item.departure)}
                  {' – '}
                  {formatDate(item.arrival)} (
                  {calculateDays(item.departure, item.arrival)})
                </div>
                <div
                  className="doc-buttons col-3 col-sm-6 col-md-3 col-lg-2 pt-1 pb-1"
                  style={{ textAlign: 'right' }}
                >
                  <a
                    target="_blank"
                    href={`${getHost()}${links === '3' ? item?.report_pdf_business_trip_url : item?.report_pdf_order_url}`}
                    className={classNames(
                      styles.btn,
                      styles.btnSmall,
                      styles.btnIcon,
                      styles.btnBlueOutline
                    )}
                    rel="noreferrer"
                  >
                    <i
                      className={classNames(styles.icon, styles.iconDownload)}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          )
        )
      })}
    </>
  )
}

const DocumentsTravelAllowances = ({ links }: any) => {
  const { reports } = useSelector(reportsSelector)
  return (
    <div className="tabs-content">
      <div className="row">
        <div className="col-12 offset-md-1 col-md-10 offset-lg-0 col-lg-12  offset-xl-1 col-xl-10">
          <div className="tab-content" data-content="tab_docs_1">
            <FilterTravel />

            {!!reports.length ? (
              //@ts-ignore
              <TravelAllowances reports={reports} links={links} />
            ) : (
              <div
                style={{ textAlign: 'center' }}
                className="pt-5 pb-5 pl-5 pr-5 bg-white border-10"
              >
                <p>
                  <i className="icon icon-70 opacity-03 icon-reports"></i>
                </p>
                <div className="font-big color-gray">
                  Таких документов за выбранный период пока нет
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentsTravelAllowances
